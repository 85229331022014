import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Endpoint } from '../../../../constants/endpoint.constant';
import { IApiResponseDetail } from '../../../../interface/common.interface';
import { Billing } from '../../../../model/billing.model';
import { PaymentRedirection } from '../../../../model/payment-redirection.model';
import { Plan } from '../../../../model/plan.model';
import { User } from '../../../../model/user.model';
import { VoucherInfo } from '../../../../model/voucher-info.model';
import { BaseService } from '../../../../services/base.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends BaseService {

  constructor(override http: HttpClient, private authService: AuthService) {
    super(http);
  }

  getPlans(): Observable<Plan[]> {
    return this.getRequest(Endpoint.GET_PLANS, {}).pipe(
      map((res: IApiResponseDetail<any>)=>{
        return res.response_output.detail.data
      })
    )

  }

  cancelSubscription(reason: string, feedback: string): Observable<IApiResponseDetail<any>> {
    return this.putRequestRaw(Endpoint.SUBSCRIPTION_CANCEL, {reason, feedback});
  }

  getCurrentSubscription():Observable<User> {
    return this.getRequestSimplified(Endpoint.MY_SUBS);
  }

  calculatePayment(planId: string, voucherCode: string, promoCode: string):Observable<Billing> {

    const user = this.authService.getCurrentUser();
    return this.postRequestSimplified(Endpoint.CALCULATE_PAYMENT, {
      user_id: user?.user_id ?? user?.id,
      plan_id: planId,
      promo_code: promoCode,
      voucher_code: voucherCode
    });
  }

  gotoPayment(planId: string, voucherCode: string, promoCode: string):Observable<PaymentRedirection> {

    const user = this.authService.getCurrentUser();
    return this.postRequestSimplified(Endpoint.GET_PAYMENT_URL, {
      user_id: user?.user_id ?? user?.id,
      plan_id: planId,
      promo_code: promoCode,
      voucher_code: voucherCode
    });
  }

  applyVoucher(voucher_code: string, plan_id: string, user_id?: number): Observable<VoucherInfo> {

    return this.postRequestSimplified(Endpoint.APPLY_VOUCHER, { voucher_code, plan_id, user_id });
  }

}
